import axios from 'axios'

const SEND_EMAIL_URL = 'https://contactemailfunction.azurewebsites.net/api/MailContactFunction'

class ContactService {
  axiosInstance = axios.create({});

  sendEmail = (data) => this.axiosInstance.post(SEND_EMAIL_URL, data)
}

export default new ContactService()
